<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			mobile-breakpoint="400"
			:loading="fetching"
			loading-text="Fetching guilds..."
			:footer-props="footerProps"
			:server-items-length="totalGuilds"
			:options.sync="options"
		>
			<template v-slot:top>
				<div class="table-header">
					<v-text-field
						v-model="search"
						label="Search guild id"
						class="mx-4 pt-6"
						outlined
						clearable
					></v-text-field>
					<v-btn class="ml-2" icon @click="resetFetch" :loading="fetching">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:item.name="{ item }">
				<div class="guild-info">
					<v-avatar size="32">
						<v-img :src="item.avatarUrl" alt="avatar" />
					</v-avatar>
					<div class="guild-info-name">
						{{ item.name }}
					</div>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<AdminGuildReportModal :guild="item" />
			</template>
		</v-data-table>
		<AdminGuildReportModal
			v-if="overrideGuild"
			:guild="overrideGuild"
			isOverride
		/>
	</div>
</template>

<script>
import AdminGuildReportModal from '@/views/admin/components/AdminGuildReportModal';

export default {
	name: 'AdminGuildReportPage',
	components: { AdminGuildReportModal },
	data: () => ({
		fetching: true,
		totalGuilds: 0,
		search: '',
		guilds: [],
		overrideGuild: null,
		headers: [
			{ text: 'Reports', value: 'reports', sortable: false },
			{ text: 'Guild Name', value: 'name', sortable: false },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Action', value: 'action', sortable: false },
		],
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
		options: {
			page: 1,
			itemsPerPage: 25,
		},
	}),
	watch: {
		options: {
			handler() {
				this.fetchGuilds();
			},
			deep: true,
		},
	},
	computed: {
		items() {
			return this.guilds.map((guild) => {
				return this.formatGuild(guild);
			});
		},
	},
	methods: {
		formatGuild(guild) {
			return {
				id: guild._id,
				avatar: guild.avatar,
				avatarUrl: this.getAvatarUrl(guild._id, guild.avatar),
				name: guild.name,
				reports: guild.reportsAgainstCount,
			};
		},
		getAvatarUrl(id, avatar) {
			return `https://cdn.discordapp.com/icons/${id}/${avatar}.png`;
		},
		async resetFetch() {
			this.options.page = 1;
			await this.fetchGuilds();
		},
		async fetchGuilds() {
			try {
				this.fetching = true;
				this.guilds = await this.$store.dispatch('getGuildReports', {
					page: this.options.page,
					guildId: this.search,
					limit: this.options.itemsPerPage,
				});
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
		async getTotalGuilds() {
			try {
				this.fetching = true;
				this.totalGuilds = await this.$store.dispatch('getTotalGuildReports');
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
	},
	async mounted() {
		await this.getTotalGuilds();
		this.fetchGuilds();
		if (this.$route.query.id) {
			try {
				let guild = await this.$store.dispatch('getGuildReports', {
					page: this.options.page,
					guildId: this.$route.query.id,
					limit: this.options.itemsPerPage,
				});
				guild = guild[0];
				if (!guild) return;
				this.overrideGuild = this.formatGuild(guild);
			} catch (err) {
				console.error(err);
				return;
			}
		}
	},
};
</script>

<style scoped>
.guild-info {
	display: flex;
}

.guild-info-name {
	padding-top: 6px;
	padding-left: 10px;
}

.table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 20px;
}
</style>
